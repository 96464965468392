import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Auth";
import { toast } from 'react-toastify';

import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import { Input, InputGroup } from "@sb1/ffe-form-react";
import { ActionButton } from "@sb1/ffe-buttons-react";

import styles from './Login.module.scss';

import { ReactComponent as DealClosed } from '../../../Assets/Images/deal_closed.svg';
import { useAxios } from "../../../AxiosHandler";
import DefaultLogo from "../../Shared/DefaultLogo/DefaultLogo";
import { config } from "../../../functions/config";

function Login() {
    const navigate = useNavigate();
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);

    const [loading, setLoading] = useState(false);

    const [wrongLogin, setWrongLogin] = useState(false);
    const [usernameError, setUsernameError] = useState(false);

    async function loginuser() {
        setLoading(true);
        await auth.signin(
            username,
            password
        ).then(data => {
            setLoading(false);
            localStorage.removeItem("selectedDealerSequence");

            // Get state from where we came from
            let state = window.history.state;
            if (state != null && state.from != null) {
                navigate(state.from + window.location.search);
            } else {
                navigate("/" + window.location.search);
            }
            
            window.scrollTo(0, 0);
        }).catch(error => {
            setLoading(false);
            console.log(error);
            if (error.response?.status >= 500 || error.status >= 500) {
                if (error.response?.status === 503 || error.status === 503) {
                    toast.error("Portalen er for øyeblikket utilgjengelig. Prøv igjen senere, eller ta kontakt med vår salgsstøtte på telefon 915 07 790 for spørsmål", { autoClose: 20000 });
                } else {
                    toast.error("Innloggingen mislyktes. Vennligst kontroller brukernavn og passord, og prøv igjen.", {autoClose: 20000});
                }
            } else {
                //toast.error("Feil brukernavn eller passord!");
                setWrongLogin(true);
            }
        });
    }

    function resetPassword() {
        if (loading) return;
        setForgotPasswordClicked(true);

        if (username == "") {
            setUsernameError(true);
            return;
        }

        setLoading(true);

        axios.single({
            url: "/api/users/passwordreset",
            method: "POST",
            data: {
                Username: username
            }
        }).then((res: any) => {
            if(res.status == 500){
                throw new Error("Tilbakestilling av passord feilet");
            }
            setLoading(false);
            toast.success("E-post sendt med instruksjoner for tilbakestilling av passord");
        }).catch((err: any) => {
            setLoading(false);
            console.log(err);
            toast.error("Tilbakestilling av passord feilet");
        });
    }

    return (
        <div className={styles.login}>
            <div className={styles.logo}>
                <DefaultLogo 
                    white={localStorage.getItem("theme") == "dark"}
                />
            </div>
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol lg="6" md="6" sm="12">
                        <h1 className={styles.mobiletitle}>Velkommen til<br/>Forhandlerportalen</h1>
                        <div className={styles.welcomeimage}>
                            <DealClosed />
                        </div>
                    </GridCol>
                    <GridCol lg="6" md="6" sm="12">
                        <div className={styles.logincontainer}>
                            <h1 className={styles.desktoptitle}>Velkommen til<br/>Forhandlerportalen</h1>
                            <div className={styles.logintext}>
                                <InputGroup
                                    label="Brukernavn"
                                    fieldMessage={usernameError ? 'Fyll inn brukernavn' : null}
                                >
                                    {inputProps => (
                                        <Input
                                            tabIndex={1}
                                            autoComplete="false"
                                            autoCorrect="false"
                                            autoFocus
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={e => {
                                                setUsername(e.target.value);
                                                setWrongLogin(false);
                                                setUsernameError(false);
                                            }}
                                            {...inputProps}
                                        />
                                    )}
                                </InputGroup>
                                <InputGroup
                                    label="Passord"
                                    fieldMessage={
                                        wrongLogin ? 'Feil brukernavn eller passord' : null
                                    }
                                >
                                    {inputProps => (
                                        <Input
                                            tabIndex={2}
                                            autoComplete="false"
                                            autoCorrect="false"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                                setWrongLogin(false);
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    loginuser();
                                                }
                                            }}
                                            {...inputProps}
                                        />
                                    )}
                                </InputGroup>
                                <p className={styles.forgotpassword} onClick={() => {
                                    resetPassword();
                                }}>Glemt passord?</p>
                                { forgotPasswordClicked && username == "" ?
                                    config.region == "ØST" ?
                                        <p className={styles.forgotusername}>Dersom du har glemt brukernavnet ditt kan du kontakte Salgsstøtte på 915 07780 for privatmarkedet eller 915 07790 for bedriftsmarked</p>
                                    : config.region == "NORD" ?
                                        <p className={styles.forgotusername}>Dersom du har glemt brukernavnet ditt kan du kontakte din forhandleransvarlig eller ringe 915 02248 (99)</p>
                                    : null
                                : null }
                                <ActionButton
                                    tabIndex={3}
                                    isLoading={loading}
                                    onClick={() => {
                                        loginuser()
                                    }}
                                >
                                    Logg inn
                                </ActionButton>
                            </div>
                        </div>
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
    );
}

export default Login;
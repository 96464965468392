import React, { useEffect } from "react";

import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";

import styles from './Settings.module.scss';
import ChangePasswordCard from "./ChangePasswordCard/ChangePasswordCard";
import GeneralSettingsCard from "./GeneralSettingsCard/GeneralSettingsCard";
import { useAxios } from "../../../AxiosHandler";
import { useAuth } from "../../../Auth";

function Settings() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    
    const [dateText, setDateText] = React.useState<string>("");
    const [clientVersion, setClientVersion] = React.useState<string>("");
    const [serverVersion, setServerVersion] = React.useState<string>("");
    const [openCard, setOpenCard] = React.useState<string | null>("changePassword");

    const [rbacTest01, setRbacTest01] = React.useState<string>("");
    const [rbacTest02, setRbacTest02] = React.useState<string>("");
    const [rbacTest03, setRbacTest03] = React.useState<string>("");

    function fetchDateFile() {
        fetch("builddate.txt")
        .then((res) => res.text())
        .then((content) => {
            setDateText(content);
        });
    }
    
    function fetchClientVersionFile() {
        fetch("version.txt")
        .then((res) => res.text())
        .then((content) => {
            setClientVersion(content);
        });
    }

    function fetchServerVersion() {
        axios.single({
            url: "/api/version",
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res: any) => {
            setServerVersion(res.data);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchDateFile();
        fetchClientVersionFile();
        fetchServerVersion();

        // If user has rbac_test_1 or rbac_test_2, we run the tests
        if (auth.hasPermissions("rbac_test_1") || auth.hasPermissions("rbac_test_2")) {
            axios.single({
                url: "/api/casenotes/testrbac",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                },
                noIntercept: true
            }).then((res: any) => {
                console.log("Expected 200 OK:", res.data);
                setRbacTest01(res.data);
            }).catch((err: any) => {
                console.log(err);
            });

            axios.single({
                url: "/api/casenotes/testrbac",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                noIntercept: true
            }).then((res: any) => {
                console.log("Expected 401 missing JWT:", res.data);
                setRbacTest02(res.data);
            }).catch((err: any) => {
                console.log(err);
            });

            let modifiedJWT = JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"];
            let splitJWT = modifiedJWT.split(".");
            let decodedJWT = JSON.parse(atob(splitJWT[1]));
            decodedJWT["usergroup"] = [decodedJWT["usergroup"], "test"];
            modifiedJWT = splitJWT[0] + "." + btoa(JSON.stringify(decodedJWT)) + "." + splitJWT[2];

            axios.single({
                url: "/api/casenotes/testrbac",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + modifiedJWT
                },
                noIntercept: true
            }).then((res: any) => {
                console.log("Expected 401 invalid JWT:", res.data);
                setRbacTest03(res.data);
            }).catch((err: any) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <div className={styles.settings}>
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol lg="6" md="12" sm="12">
                        <ChangePasswordCard
                            open={openCard == "changePassword"}
                            onClick={() => setOpenCard(openCard == "changePassword" ? null : "changePassword")}
                        />
                        <GeneralSettingsCard
                            open={openCard == "changePassword"}
                        />
                        { auth.hasPermissions("rbac_test_1") || auth.hasPermissions("rbac_test_2") ? 
                            <>
                                <h3>RBAC tests towards Genus endpoint, which requires usergroup "rbac_test_1"</h3>
                                <p>RBAC Genus test 01 (Current token): <br/>{JSON.stringify(rbacTest01)}</p>
                                <p>RBAC Genus test 02 (Token removed from request): <br/>{JSON.stringify(rbacTest02)}</p>
                                <p>RBAC Genus test 03 (Token modified in client): <br/>{JSON.stringify(rbacTest03)}</p>

                                <hr/>
                                <h3>RBAC tests in GUI display</h3>
                                <p>rbac_test_1: <br/>{auth.hasPermissions("rbac_test_1") ? "true" : "false"}</p>
                                <p>rbac_test_2: <br/>{auth.hasPermissions("rbac_test_2") ? "true" : "false"}</p>
                                <p>rbac_test_1 and rbac_test_2: <br/>{auth.hasPermissions("rbac_test_1", "rbac_test_2") ? "true" : "false"}</p>
                            </>
                        : null }
                    </GridCol>
                    <GridCol lg="6" md="12" sm="12">
                        <div
                            className={styles.version}
                        >
                            <p>{clientVersion} / {serverVersion} / {dateText}</p>
                        </div>
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
    );
}

export default Settings;
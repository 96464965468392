import React from "react";

import styles from './SalesmanSalesMonth.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";

import StatisticsCompetitionTable from "../../../../Shared/StatisticsCompetitionTable/StatisticsCompetitionTable";

function SalesmanSalesMonth(props: SalesmanSalesMonthProps) {
    const columns = [ 'Selger', 'Volum', 'Snitt nysalgsrente' ];

    return (
        <div className={styles.salesmansalesmonth}>
            <ContentCard
                title="Salg per selger inneværende måned"
                open={true}
                disableClick={true}
            >
                <StatisticsCompetitionTable
                    columns={columns}
                    data={props.data}
                />
            </ContentCard>
        </div>
    );
}

interface SalesmanSalesMonthProps {
    data: any;
}

export default SalesmanSalesMonth

import React from "react";

import styles from './PointsOverall.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import StatisticsCompetitionTable from "../../../../Shared/StatisticsCompetitionTable/StatisticsCompetitionTable";

function PointsOverall(props: PointsOverallProps) {
    const columns = [ 'Selger', 'Forhandler', 'Tot. poeng' ];

    return (
        <div className={styles.pointsoverall}>
            <ContentCard
                title="Poengoversikt hittil i år"
                open={true}
                disableClick={true}
            >
                <StatisticsCompetitionTable
                    columns={columns}
                    data={props.data}
                />
            </ContentCard>
        </div>
    );
}

interface PointsOverallProps {
    data: any;
}

export default PointsOverall
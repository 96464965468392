import { Userdata } from "./Userdata";

export class User {
    constructor(user?: User) {
        Object.assign(this, user);
    }

    public jwt: string = "";
    public RefreshToken: string = "";
    public DefaultDealerSequence: number | undefined = undefined;
    public userdata?: Userdata | undefined = undefined;
    public usergroup?: Array<string> = [];
}
import React from "react";

import styles from './FeedbackModal.module.scss';
import { TextArea } from "@sb1/ffe-form-react";
import { ActionButton } from "@sb1/ffe-buttons-react";
import { useAxios } from "../../../AxiosHandler";

import { toast } from 'react-toastify';

function FeedbackModal(props: FeedbackModalProps) {
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    
    const [feedback, setFeedback] = React.useState("");

    function handleClose() {
        setFeedback("");
        props.onClose();
    }

    async function sendFeedback() {
        axios.single({
            url: "/api/feedback",
            data: {
                Feedback: feedback
            },
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            handleClose();
            toast.success("Tilbakemeldingen er sendt inn!");
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Sending av tilbakemelding feilet. Vennligst prøv på nytt.");
        });
    }

    return (
        <div className={styles.feedbackModal + " " + "noprint"} style={{ display: props.open ? "block" : "none" }} onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains(styles.feedbackModal)) {
                handleClose();
            }
        }}>
            <div className={styles.content}>
                <div className={styles.feedbackHeader}>
                    <h1>Gi oss tilbakemelding!</h1>
                    <span
                        onClick={() => {
                            handleClose();
                        }}
                        className={"material-symbols-outlined" + " " + styles.closebtn}
                    >close</span>
                </div>
                <div className={styles.feedbackContent}>
                    <TextArea
                        placeholder="Din tilbakemelding..."
                        value={feedback}
                        id="feedback-textarea"
                        rows={8}
                        onChange={(e) => {
                            setFeedback(e.target.value);
                        }}
                    />
                    <ActionButton
                        disabled={feedback == ""}
                        onClick={() => { sendFeedback(); }}
                    >
                        Send inn tilbakemelding
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}

class Feedback{
    feedbackText!: string;
}
class FeedbackModalProps{
    open!: boolean;
    onClose!: () => void;
}

export default FeedbackModal;
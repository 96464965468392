import React, { ReactNode, useRef } from "react";

import styles from './ObjectSupplierCard.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import { Input, InputGroup } from "@sb1/ffe-form-react";
import { Loanapplication as LoanapplicationModel} from "../../../../../model/Loanapplication";
import { useLoanapplication } from "../../../../../LoanapplicationContext";
import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";
import InputWithUsability from "../../../../Shared/InputWithUsability/InputWithUsability";
import { toast } from "react-toastify";
import customerFunctions from "../../../../../functions/customerFunctions";

function ObjectSupplierCard(props: ObjectSupplierCardProps) {
    const auth = useAuth();
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const prevSocialSecurityNumberRef = useRef<string>('');
    
    // const [objectSupplierSegment, setObjectSupplierSegment] = React.useState("bedrift");
    const [displayLabelCorrection, setDisplayLabelCorrection] = React.useState(false);

    return (
        <ContentCard disabled={props.disabled} open={props.open} title="Selgerinfo" className={styles.objectsuppliercard}>
           {/*  <TabSelector
                tabs={[
                    {
                        name: "Bedrift/org. selger", 
                        value: "bedrift"
                    }, {
                        name: "Privat selger", 
                        value: "privat"
                    }
                ] as any}
                selected={objectSupplierSegment}
                onClick={(value: string) => {
                    setObjectSupplierSegment(value)
                }}
            />

            <InputGroup
                label={ objectSupplierSegment == "privat" ? "Personnummer (11 siffer)" : "Organisasjonsnummer (9 siffer)" }
                tooltip={ objectSupplierSegment == "privat" ?
                        "Personnummer på 11 siffer. Resten av feltene vil bli fylt ut automatisk."
                    :
                        "Organisasjonsnummer på 9 siffer eller personnummer på 11 siffer dersom enkeltpersonforetak. Resten av feltene vil bli fylt ut automatisk."
                }
                fieldMessage={displayLabelCorrection ?
                        (objectSupplierSegment == "privat" &&
                        props.currentLoanapplication?.objectSupplierNumber?.length != 11) ?
                            "Ugyldig personnummer"
                        : (objectSupplierSegment == "bedrift" &&
                        props.currentLoanapplication?.objectSupplierNumber?.length != 9) ?
                            "Ugyldig organisasjonsnummer"
                        :
                            null
                    : null
                }
            >
                {inputProps => (
                    <InputWithUsability
                        type="text"
                        name="socialsecuritynumber"
                        useseparatorformatter
                        placeholder={props.currentLoanapplication?.customerLastName != "" && props.currentLoanapplication?.customerLastName != undefined ? "Søk for å bytte kunde .." : "Søk .."}
                        customformatter={
                            props.currentLoanapplication?.objectSupplierNumber?.length != 9 ?
                                (value: number): string | ReactNode => {
                                    if (value != 0) {
                                        return <span>{value.toString().replace(/(\d{6})(\d{5})/, "$1 $2")}</span>;
                                    } else {
                                        return "";
                                    }
                                }
                            :
                                (value: number): string | ReactNode => {
                                    if (value != 0) {
                                        return value.toString().replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
                                    } else {
                                        return "";
                                    }
                                }
                        }
                        value={props.currentLoanapplication?.objectSupplierNumber || ""}
                        onChange={e => {
                            setDisplayLabelCorrection(false);
                            loanapplication.setObjectSupplierNumber(e.target.value.replace(/\s/g, ''));
                        }}
                        onBlur={(e) => {
                            const currentSSN = e.target.value.replace(/\s/g, '');
                            const prevSSN = prevSocialSecurityNumberRef.current;
                            
                            if (currentSSN === prevSSN) { return; }

                            setDisplayLabelCorrection(true);

                            if (e.target.value.replace(/\s/g, '').length == 11) {
                                customerFunctions.searchPerson(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    loanapplication.setObjectSupplierSequence(res.Sequence);
                                    loanapplication.setObjectSupplierName((res.FirstName ? (res.FirstName + " ") : "") + (res.LastName || ""));
                                    if (res == "") {
                                        toast.error("Person ikke funnet");
                                    }
                                }).catch((err: any) => {
                                    toast.error("Person ikke funnet");
                                    loanapplication.setObjectSupplierSequence(undefined);
                                    loanapplication.setObjectSupplierName(undefined);
                                });
                            } else if (e.target.value.replace(/\s/g, '').length == 9 && props.currentLoanapplication?.segment != "Privat") {
                                customerFunctions.searchCompany(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    loanapplication.setObjectSupplierSequence(res.Sequence);
                                    loanapplication.setObjectSupplierName(res.CompanyName);
                                    if (res == "") {
                                        toast.error("Organisasjon ikke funnet");
                                    }
                                }).catch((err: any) => {
                                    toast.error("Organisasjon ikke funnet");
                                    loanapplication.setObjectSupplierSequence(undefined);
                                    loanapplication.setObjectSupplierName(undefined);
                                });
                            } else {
                                if (e.target.value.replace(/\s/g, '').length != 0) {
                                    loanapplication.setObjectSupplierSequence(undefined);
                                    loanapplication.setObjectSupplierName("");
                                }
                            }
                            prevSocialSecurityNumberRef.current = currentSSN;
                        }
                    }
                        {...inputProps}
                    />
                )}
            </InputGroup> */}
            <InputGroup
                label={ (props.currentLoanapplication?.objectSupplierNumber?.length == 11 && displayLabelCorrection) ?
                        "Personnummer (11 siffer)"
                    : props.currentLoanapplication?.objectSupplierNumber?.length == 9 && displayLabelCorrection ?
                        "Organisasjonsnummer (9 siffer)"
                    :
                        "Organisasjonsnummer eller Personnummer"
                }
                tooltip={ props.currentLoanapplication?.objectSupplierNumber?.length == 11 && displayLabelCorrection ?
                        "Personnummer på 11 siffer. Resten av feltene vil bli fylt ut automatisk."
                    :
                        "Organisasjonsnummer på 9 siffer eller personnummer på 11 siffer dersom enkeltpersonforetak. Resten av feltene vil bli fylt ut automatisk."
                }
                fieldMessage={
                    props.currentLoanapplication?.objectSupplierNumber?.length != 11 &&
                        props.currentLoanapplication?.objectSupplierNumber?.length != 9 &&
                        props.currentLoanapplication?.objectSupplierNumber != "" && 
                        displayLabelCorrection
                    ?
                        "Ugyldig organisasjonsnummer eller personnummer"
                    : null
                }
            >
                {inputProps => (
                    <InputWithUsability
                        type="text"
                        name="socialsecuritynumber"
                        useseparatorformatter
                        placeholder={props.currentLoanapplication?.objectSupplierName != "" && props.currentLoanapplication?.objectSupplierName != undefined ? "Søk for å bytte selger .." : "Søk .."}
                        customformatter={
                            props.currentLoanapplication?.objectSupplierNumber?.length != 9 ?
                                (value: number): string | ReactNode => {
                                    if (value != 0) {
                                        return <span>{value.toString().replace(/(\d{6})(\d{5})/, "$1 $2")}</span>;
                                    } else {
                                        return "";
                                    }
                                }
                            :
                                (value: number): string | ReactNode => {
                                    if (value != 0) {
                                        return value.toString().replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
                                    } else {
                                        return "";
                                    }
                                }
                        }
                        value={props.currentLoanapplication?.objectSupplierNumber || ""}
                        onChange={e => {
                            setDisplayLabelCorrection(false);
                            loanapplication.setObjectSupplierNumber(e.target.value.replace(/\s/g, ''));
                        }}
                        onBlur={(e) => {
                            const currentSSN = e.target.value.replace(/\s/g, '');
                            const prevSSN = prevSocialSecurityNumberRef.current;
                            
                            if (currentSSN === prevSSN) { return; }

                            setDisplayLabelCorrection(true);

                            if (e.target.value.replace(/\s/g, '').length == 11) {
                                customerFunctions.searchPerson(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    loanapplication.setObjectSupplierSequence(res.Sequence);
                                    loanapplication.setObjectSupplierName((res.FirstName ? (res.FirstName + " ") : "") + (res.LastName || ""));
                                    loanapplication.setObjectSupplierContact(undefined);
                                    loanapplication.setObjectSupplierEmail(res.Email);
                                    loanapplication.setObjectSupplierPhone(res.Mobile);
                                    if (res == "") {
                                        toast.error("Person ikke funnet");
                                    }
                                }).catch((err: any) => {
                                    toast.error("Person ikke funnet");
                                    loanapplication.setObjectSupplierSequence(undefined);
                                    loanapplication.setObjectSupplierName(undefined);
                                });
                            } else if (e.target.value.replace(/\s/g, '').length == 9) {
                                customerFunctions.searchCompany(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    loanapplication.setObjectSupplierSequence(res.Sequence);
                                    loanapplication.setObjectSupplierName(res.CompanyName);
                                    loanapplication.setObjectSupplierContact((res.FirstName ? (res.FirstName + " ") : "") + (res.LastName || ""));
                                    loanapplication.setObjectSupplierEmail(res.Email);
                                    loanapplication.setObjectSupplierPhone(res.Mobile);
                                    if (res == "") {
                                        toast.error("Organisasjon ikke funnet");
                                    }
                                }).catch((err: any) => {
                                    toast.error("Organisasjon ikke funnet");
                                    loanapplication.setObjectSupplierSequence(undefined);
                                    loanapplication.setObjectSupplierName(undefined);
                                    loanapplication.setObjectSupplierContact(undefined);
                                    loanapplication.setObjectSupplierEmail(undefined);
                                    loanapplication.setObjectSupplierPhone(undefined);
                                });
                            } else {
                                if (e.target.value.replace(/\s/g, '').length != 0) {
                                    loanapplication.setObjectSupplierSequence(undefined);
                                    loanapplication.setObjectSupplierName("");
                                    loanapplication.setObjectSupplierContact(undefined);
                                    loanapplication.setObjectSupplierEmail("");
                                    loanapplication.setObjectSupplierPhone("");
                                }
                            }
                            prevSocialSecurityNumberRef.current = currentSSN;
                        }
                    }
                        {...inputProps}
                    />
                )}
            </InputGroup> 
            &nbsp; 

            <InputGroup
                label={ "Navn" }
                //tooltip="Fornavn på kunden. Inkludert dobbeltnavn."
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="name"
                        disabled={true}
                        value={props.currentLoanapplication?.objectSupplierName || ""}
                        onChange={e => {
                            loanapplication.setObjectSupplierName(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            {props.currentLoanapplication?.objectSupplierNumber?.length != 11 ?
                <>
                    <InputGroup
                        label={ "Kontaktperson - navn" }
                        //tooltip="Fornavn på kunden. Inkludert dobbeltnavn."
                        //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                    >
                        {inputProps => (
                            <Input
                                type="text"
                                name="name"
                                value={props.currentLoanapplication?.objectSupplierContact || ""}
                                onChange={e => {
                                    loanapplication.setObjectSupplierContact(e.target.value);
                                }}
                                {...inputProps}
                            />
                        )}
                    </InputGroup>
                    &nbsp;
                </>
            : null}
            <InputGroup
                label={ "Kontaktperson - e-post" }
                //tooltip="REPLACE ME PLEASE - E-post"
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="email"
                        value={props.currentLoanapplication?.objectSupplierEmail || ""}
                        onChange={e => {
                            loanapplication.setObjectSupplierEmail(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            <InputGroup
                label={ "Kontaktperson - telefon" }
                //tooltip="REPLACE ME PLEASE - Telefon"
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="phone"
                        value={props.currentLoanapplication?.objectSupplierPhone || ""}
                        onChange={e => {
                            loanapplication.setObjectSupplierPhone(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
        </ContentCard>
    );
}

interface ObjectSupplierCardProps {
    currentLoanapplication: LoanapplicationModel | undefined;
    disabled: boolean;
    open: boolean;
}

export default ObjectSupplierCard;